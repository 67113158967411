<template>
    <div class="login">
        <div class="login__block" id="l-login">
            <div class="login__block__header">
                <i class="zmdi zmdi-account-circle"></i>
                Welcome back!
            </div>
            <div class="login__block__body">
                <form @submit.prevent="login">
                    <div class="form-group form-group--centered">
                        <label>Email Address</label>
                        <input type="text" class="form-control" v-model="user.email" required>
                        <i class="form-group__bar"></i>
                    </div>
                    <div class="form-group form-group--centered">
                        <label>Password</label>
                        <input type="password" class="form-control" v-model="user.password" required>
                        <i class="form-group__bar"></i>
                    </div>
                    <button type="submit" class="btn btn-block login__block__btn" :disabled="isProcessing">
                        <loading v-if="isProcessing" />
                        <span v-else>LOGIN</span>
                    </button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from "firebase/app"
export default {
    data(){
        return {
            user: { 
                email: "", 
                password: "" 
            },
            isProcessing: false,
        }
    },
    methods: {
        async login(){
            try {
                
                this.isProcessing = true
                
                const { email, password } = this.user;
                
                const authResponse = await firebase.auth().signInWithEmailAndPassword(email, password)
                
                const { user } = authResponse;
                
                const { uid } = user

                const userResponse = await firebase.firestore().collection("users").doc(uid).get()
                if (userResponse.exists) {
                    const data = userResponse.data()
                    const userData = { uid, ...data }
                    this.$store.dispatch("userLogin", userData)
                    this.$router.push("/")
                    this.isProcessing = false
                    this.$toast.success(`Welcome back ${data.fullname}`)
                    return
                }
                this.isProcessing = false
                return this.$toast.error("User not found")
            } catch (error) {
                this.$toast.error(error.message)
                this.isProcessing = false
            }
        }
    },
    components: {
        "loading": () => import("@/components/Loaders/Button.vue"),
    }
}
</script>